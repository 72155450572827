.top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
    background: none;
    margin-top: 40px;
    padding-bottom: 80px;
    
  }
  
  .top-section-heading {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
    max-width: 80%;
    cursor:pointer;
    word-wrap: break-word;
  }
  
  .top-section-description {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 15px;
    max-width: 69%;
  }
  
  .top-section-button {
    background-color: black;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: bold;
    padding: 1rem 2rem;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .top-section-button:hover {
    background-color: #2d2d2d;
  }
  